import React from 'react'
import '../styles/cookies.scss'
import Layout from "../components/layout";

const CookiesPolicy = () => (
    <Layout>
        <div className='section-cookies'>
            <div>
                <div className='heading'>Cookies Policy</div>

                <div className='content'>
                    <h3>1. What is a Cookie?</h3>
                    <p>A cookie is a small file of letters and numbers downloaded on to your computer when you access certain websites. In general, cookies allow a website to recognise a user’s computer.
                    The most important thing to know about cookies placed by DD Zone, Inc. is that they make our website a bit more user-friendly, for example, by remembering site preferences and language settings.
                    </p>
                </div>

                <div className='content'>
                    <h3>2. Why does we use Cookies?</h3>
                    <p>We use these cookies and other similar technologies for a number of purposes, including: </p>
                    <ul>
                        <li>Keeping you signed in</li>
                        <li>Keeping your preferences</li>
                        <li>To provide you with the service you chose to receive from us</li>
                        <li>Understanding how you use our website</li>
                        <li>To monitor and analyze the performance, operation, and effectiveness in order to improve the DD Zone Platform</li>
                        <li>To understand and improve the impact of our marketing campaigns</li>
                        <li>For security needs and fraud-protection purposes and in order to identify and prevent  cyber-attacks</li>
                    </ul>
                </div>

                <div className='content'>
                    <h3>3. Types of Cookies:</h3>
                    <li><strong>“First-party Cookies”</strong> - Cookies that are placed by DD Zone, Inc.</li>
                    <li><strong>“Third-party Cookies”</strong> - Cookies that are placed and used by third-party companies which are DD Zone, Inc.’s partners</li>
                    <span>
                        It is important to note that DD Zone, Inc. cannot access third-party cookies, nor can other third-party organizations access the data in the cookies we use on our website.
                    </span>
                </div>

                <div className='content'>
                    <h3>4. How long Cookies stay on device?</h3>
                    <p>Depending on their function, Cookies may have different durations. There are session cookies and persistent cookies: </p>
                    <ul>
                        <li>Session cookies only last for your online session. It means that the browser deletes these cookies once you close your browser</li>
                        <li>Persistent cookies stay on your device after the browser has been closed and last for the period of time specified in the cookie</li>
                    </ul>
                </div>

                <div className='content'>
                    <h3>5. What kind of Cookies we store?</h3>
                    <p>The cookies used on our website fall into one of four categories: </p>
                    <ul>
                        <li>Essential Cookies let you move around the website and use essential features like secure and private areas.</li>
                        <li>Analytic Cookies let us understand how you use our website (e.g. which pages you visit), to provide statistics on how our website is used, improve the website by identifying any errors, and performance issues.</li>
                        <li>Functional cookies are cookies used to remember choices users make to improve their experience (e.g. language).</li>
                        <li>Marketing cookies are used to collect information about the impact of our marketing campaigns performed in other websites on users and non-users.</li>
                    </ul>
                    <span>
                        <br/><span>Alternately, it is also possible to stop your browser from accepting cookies altogether by changing your browser’s cookie settings. You can usually find these settings in the “options” or “preferences” menu of your browser.</span>
                        <br/><br/><span>Please note that deleting our cookies, disabling future cookies or tracking technologies may prevent you from accessing certain areas or features of our Services, or may otherwise adversely affect your user experience.</span>
                    </span>
                </div>

            </div>
        </div>
    </Layout>
);

export default CookiesPolicy
